.section.publishers {
  padding-top: 138px;
  padding-bottom: 47px;
  background: $color-pale;
  @include __tablet {
    padding-top: 100px;
  }
  @include __phone {
    padding-top: 70px;
    padding-bottom: 26px;
  }
}
.publishers__summary {
  margin: 0 auto 0;
  max-width: 1005px;
  text-align: center;
  @include __phone {
    padding: 0 32px;
  }
}
.summary__first {
  @include __clearfix;
  @include __tablet {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.summary__group {
  box-sizing: border-box;
  border-radius: 19px;
  border: solid 2px #e3ebf3;
  background-color: #ffffff;
}
.summary__box {
  box-sizing: border-box;
}
.summary__group-pre {
  float: left;
  width: 642px;
  height: 372px;
  padding: 0 20px;
  @include __tablet {
    box-sizing: border-box;
    width: 456px;
    height: 460px;
  }
  @include __phone {
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }
  .summary__box {
    float: left;
    width: 50%;
    @include __phone {
      float: none;
      width: 100%;
      &:nth-child(2) {
        padding-bottom: 35px;
      }
      &:last-child {
        padding-bottom: 50px;
      }
    }
  }
  .summary__header {
    box-sizing: border-box;
    padding-top: 3px;
    height: 24px;
    margin: 24px auto 13px auto;
    font-size: 13px;
    font-weight: bold;
    line-height: 14px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #43dae6;
    span {
      display: inline-block;
      border-bottom: 1px solid #43dae6;
    }
  }
}
.summary__group-after {
  float: left;
  width: 340px;
  height: 372px;
  padding: 0 20px;
  margin-left: 23px;
  @include __tablet {
    width: 234px;
    height: 460px;
  }
  @include __phone {
    width: 100%;
    height: auto;
    padding-bottom: 40px;
    margin-left: 0;
    margin-top: 20px;
  }
  .summary__header {
    box-sizing: border-box;
    padding-top: 4px;
    height: 24px;
    margin: 24px auto 14px auto;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.4px;
    text-align: center;
    color: #40a6fa;
    span {
      display: inline-block;
      border-bottom: 1px solid #40a6fa;
    }
  }
}
.summary__second {
  @include __clearfix;
  margin-top: 78px;
  @include __phone {
    margin-top: 68px;
  }
}
.summary__triple-container {
  @include __tablet {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.summary__group-triple {
  position: relative;
  float: left;
  width: 266px;
  height: 324px;
  padding-top: 34px;
  margin-top: 46px;
  @include __tablet {
    width: 200px;
  }
  @include __phone {
    margin-top: 72px;
    width: 100%;
    height: auto;
    padding-bottom: 38px;
  }
  &:nth-child(1) {
    margin-right: 103px;
    @include __tablet {
      margin-right: 54px;
    }
    @include __phone {
      margin-right: 0;
      margin-top: 38px;
    }
  }
  &:nth-child(2) {
    margin-right: 104px;
    @include __tablet {
      margin-right: 54px;
    }
  }
  &:last-child {
    border-color: #40a6fa;
  }
}
.summary__step-plus {
  position: absolute;
  right: -73px;
  top: 144px;
  width: 40px;
  height: 40px;
  background: url(../img/v2/img-step-plus.cfeb210c.svg) no-repeat;
  background-size: 100%;
  @include __tablet {
    right: -49px;
  }
  @include __phone {
    position: absolute;
    right: 0;
    left: 50%;
    top: auto;
    bottom: -54px;
    width: 36px;
    height: 36px;
    margin-left: -18px;
  }
}
.summary__step-arrow {
  position: absolute;
  right: -73px;
  top: 144px;
  width: 40px;
  height: 40px;
  background: url(../img/v2/img-step-arrow.2ab8270f.svg) no-repeat;
  background-size: 100%;
  @include __tablet {
    right: -49px;
  }
  @include __phone {
    position: absolute;
    right: 0;
    left: 50%;
    top: auto;
    bottom: -54px;
    width: 36px;
    height: 36px;
    margin-left: -18px;
    transform: rotate(90deg);
  }
}
.summary__title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.39;
  color: #26344a;
  margin-bottom: 9px;
  @include __phone {
    margin-bottom: 15px;
  }
  @at-root .summary__first &::first-letter {
    font-size: 21px;
  }
  @at-root .summary__group-triple & {
    margin-bottom: 13px;
  }
}
.summary__desc {
  font-size: 16px;
  font-weight: 300;
  color: #5e6977;
  line-height: 1.5;
  @include __tablet {
    padding-left: 5px;
    padding-right: 5px;
    br {
      display: none;
    }
  }
}
.summary__icon {
  display: block;
  width: 90px;
  height: 90px;
  margin: 0 auto 17px;
  @at-root .summary__group-triple & {
    margin-bottom: 20px;
  }
}
.summary__icon-1 {
  background: url(../img/v2/img-pre-consulting.3f4a422e.svg) no-repeat 50% 50%;
}
.summary__icon-2 {
  background: url(../img/v2/img-pre-sdk.41e93ce5.svg) no-repeat 50% 50%;
}
.summary__icon-3 {
  background: url(../img/v2/img-after-experts.0704626e.svg) no-repeat 50% 50%;
}
.summary__icon-4 {
  background: url(../img/v2/img-service-sales.4705b3be.svg) no-repeat 50% 50%;
}
.summary__icon-5 {
  background: url(../img/v2/img-service-mediation.c01aaefc.svg) no-repeat 50% 50%;
}
.summary__icon-6 {
  background: url(../img/v2/img-service-adiscope.698e084c.svg) no-repeat 50% 50%;
}
