.contents {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background-color: #fff;
}
.section {
  @include __phone {
    padding: 70px 0 0 0;
  }
}
.section__name {
  padding-bottom: 15px;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #26344a;
  @include __phone {
    font-size: 15px;
    padding-bottom: 20px;
  }
  span {
    display: inline-block;
    border-bottom: 2px solid #8f97a4;
  }
}
.section__title {
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #26344a;
  line-height: 1.35;
  letter-spacing: -1px;
  font-weight: bold;
  @include __phone {
    font-size: 26px;
    line-height: 1.38;
  }
}
.section__desc {
  margin-top: 16px;
  margin-bottom: 47px;
  font-size: 17px;
  line-height: 1.65;
  text-align: center;
  color: #5e6977;
  @include __phone {
    font-size: 15px;
    line-height: 1.87;
  }
}

@import 'contents/publisher';
@import 'contents/features';
@import 'contents/formats';
@import 'contents/networks';
@import 'contents/contact';
