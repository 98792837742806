@import 'reset';
@import 'common/variable';
@import 'common/mixin';
@import 'common/extend';

html,
body {
  height: 100%;
}
html {
  overflow-y: scroll;
}
.noscroll {
  // width: 100%;
  // position: fixed;
  // overflow: hidden;
}
// google captcha 메일폼 입력시에만
.grecaptcha-badge {
  opacity: 0;
  z-index: 0;
  transition: opacity 0.5s, z-index 0.24s, right 0.3s ease !important;
}
.mail--open .grecaptcha-badge {
  opacity: 1;
  z-index: 1000;
}
#header__logo,
#nav-mobile__logo {
  cursor: pointer;
}
.br-pc {
  display: block;
}
.br-tablet {
  display: none;
}
.br-phone {
  display: none;
}
.br-wide-phone {
  display: none;
}
.br-narrow-phone {
  display: none;
}

@media (max-width: 1100px) and (min-width: 761px) {
  .br-pc {
    display: none;
  }
  .br-tablet {
    display: block;
  }
}
@media (max-width: 760px) {
  .br-pc {
    display: none;
  }
  .br-tablet {
    display: none;
  }
  .br-wide-phone {
    display: block;
  }
  .br-phone {
    display: block;
  }
}
@media (max-width: 375px) {
  .br-pc {
    display: none;
  }
  .br-tablet {
    display: none;
  }
  .br-wide-phone {
    display: none;
  }
  .br-phone {
    display: block;
  }
  .br-narrow-phone {
    display: block;
  }
}

@import 'header';
@import 'contents';
@import 'footer';
@import 'mailform';
@import 'visible';
@import 'svg';
