@mixin __mobile-feature-image($width, $height) {
  & {
    position: relative;
    left: 0;
    top: 0;
    width: #{$width}px;
    height: #{$height}px;
    margin: 40px auto 70px auto;
  }
}
@mixin __tablet-feature-image($left, $top, $width, $height) {
  & {
    position: absolute;
    left: $left + 0px;
    top: $top + 0px;
    width: $width + 0px;
    height: $height + 0px;
  }
}

.features {
  padding-top: 138px;
  padding-bottom: 123px;
  @include __tablet {
    padding-top: 70px;
    padding-bottom: 204px;
  }
  @include __phone {
    padding-top: 70px;
    padding-bottom: 0;
  }
}
.feature {
  position: relative;
  width: 944px;
  margin: 0 auto;
  @include __tablet {
    box-sizing: border-box;
    width: 638px;
    margin: 0 auto;
  }
  @include __phone {
    width: auto;
    margin: 0 25px;
  }
  .feature-title {
    position: relative;
    font-size: 32px;
    color: #26344a;
    letter-spacing: -1px;
    line-height: 1.3;
    margin-bottom: 25px;
    font-weight: 600;
    z-index: 3;
    @include __tablet {
      padding-left: 0 !important;
    }
    @include __phone {
      width: auto !important;
      padding-left: 0 !important;
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 16px;
    }
  }
  .feature-desc {
    position: relative;
    font-size: 17px;
    font-weight: 300;
    color: #5e6977;
    line-height: 1.65;
    z-index: 3;
    @include __tablet {
      padding-left: 0 !important;
    }
    @include __phone {
      padding-left: 0 !important;
      width: auto !important;
      font-size: 15px;
      line-height: 1.6;
    }
  }
}
.feature-1 {
  margin-top: 221px;
  @include __tablet {
    margin-top: 132px;
  }
  @include __phone {
    margin-top: 74px;
  }
  .feature-title {
    width: 393px;
  }
  .feature-desc {
    width: 457px;
    @include __tablet {
      width: 353px;
    }
  }
  .feature-img {
    position: absolute;
    width: 556px;
    height: 464px;
    left: 420px;
    top: -89px;
    @include __tablet {
      @include __tablet-feature-image(141, 125, 500, 418);
    }
    @include __phone {
      @include __mobile-feature-image(306, 255);
    }
  }
}
.feature-2 {
  margin-top: 259px;
  @include __tablet {
    margin-top: 425px;
  }
  @include __phone {
    margin-top: 0;
  }
  .feature-title {
    white-space: nowrap;
    word-break: keep-all;
    padding-left: 484px;
  }
  .feature-desc {
    padding-left: 484px;
  }
  .feature-img {
    position: absolute;
    width: 666px;
    height: 448px;
    left: -30px;
    top: 72px;
    @include __tablet {
      @include __tablet-feature-image(20, 245, 599, 403);
    }
    @include __phone {
      @include __mobile-feature-image(366, 246);
    }
  }
}
.feature-3 {
  margin-top: 582px;
  @include __tablet {
    margin-top: 585px;
  }
  @include __phone {
    margin-top: 0;
  }
  .feature-title {
    width: 369px;
  }
  .feature-desc {
    width: 440px;
    @include __tablet {
      width: 570px;
    }
  }
  .feature-img {
    position: absolute;
    width: 555px;
    height: 455px;
    left: 412px;
    top: -145px;
    @include __tablet {
      @include __tablet-feature-image(69, 219, 500, 410);
    }
    @include __phone {
      @include __mobile-feature-image(305, 250);
    }
  }
}
.feature-4 {
  margin-top: 287px;
  @include __tablet {
    margin-top: 565px;
  }
  @include __phone {
    margin-top: 0;
  }
  .feature-title {
    padding-left: 485px;
  }
  .feature-desc {
    padding-left: 485px;
  }
  .feature-img {
    position: absolute;
    width: 360px;
    height: 404px;
    left: 13px;
    top: -99px;
    @include __tablet {
      @include __tablet-feature-image(157, 248, 324, 364);
    }
    @include __phone {
      @include __mobile-feature-image(198, 226);
    }
  }
}
.feature-5 {
  margin-top: 361px;
  @include __tablet {
    margin-top: 591px;
  }
  @include __phone {
    margin-top: 0;
  }
  .feature-title {
    width: 393px;
  }
  .feature-desc {
    width: 457px;
  }
  .feature-img {
    position: absolute;
    width: 410px;
    height: 404px;
    left: 472px;
    top: -94px;
    @include __tablet {
      @include __tablet-feature-image(92, 205, 455, 420);
    }
    @include __phone {
      @include __mobile-feature-image(329, 296);
    }
  }
}
.feature-6 {
  margin-top: 347px;
  @include __tablet {
    margin-top: 505px;
  }
  @include __phone {
    margin-top: 0;
  }
  .feature-title {
    white-space: nowrap;
    padding-left: 517px;
    @include __tablet {
      width: 290px;
      white-space: normal;
    }
  }
  .feature-desc {
    padding-left: 517px;
    @include __tablet {
      width: 400px;
    }
  }
  .feature-img {
    position: absolute;
    width: 457px;
    height: 425px;
    left: 0;
    top: -91px;
    @include __tablet {
      @include __tablet-feature-image(131, 131+84, 380, 344);
    }
    @include __phone {
      @include __mobile-feature-image(334, 300);
    }
  }
}

.feature-img-1 {
  background: url('../img/v2/img-special-rewarded-1440.5c193878.svg') no-repeat 0 0;
  background-size: 100%;
}
.feature-img-2 {
  background: url('../img/v2/img-special-offerwall-1440.8cd3d422.svg') no-repeat 0 0;
  background-size: 100%;
}
.feature-img-3 {
  background: url('../img/v2/img-special-security-1440.e11af241.svg') no-repeat 0 0;
  background-size: 100%;
}
.feature-img-4 {
  background: url('../img/v2/img-special-1-sdk-1440.cfc46988.svg') no-repeat 0 0;
  background-size: 100%;
}
.feature-img-5 {
  background: url('../img/v2/img-special-support-1440.9e3490da.svg') no-repeat 0 0;
  background-size: 100%;
}
.feature-img-6 {
  background: url('../img/v2/img-special-report-1440.a865855e.svg') no-repeat 0 0;
  background-size: 100%;
}