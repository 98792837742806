%open-nav {
  opacity: 0;
  .nav-mobile--open & {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.nav-mobile--open .nav-mobile {
  display: block;
  z-index: 100;
}
#nav-mobile__logo {
  position: absolute;
  left: 20px;
  top: 23px;
  width: 143px;
  height: 39px;
  z-index: 3;
  @include __tablet {
    left: 30px;
    top: 28px;
    img {
      width: 157px;
      height: 43px;
    }
  }
}
.nav-mobile {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: z-index 0.25s 0.25s;
  .nav-mobile--open & {
    transition-delay: 0s;
  }
  .nav-mobile__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 59, 86, 0.95);
    opacity: 0;
    transition: opacity 0.4s $easeinout 0.1s, transform 0.4s $easeinout;
    @extend %open-nav;
  }
  .nav-mobile__body {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 180px;
    height: 310px;
    margin: -149px 0 0 -90px;
    z-index: 3;
    text-align: center;
    a {
      display: block;
      margin: 0 auto;
      padding: 20px 0;
      font-size: 25px;
      color: #fff;
      text-decoration: none;
      opacity: 0;
      transition: opacity 0.4s $easeinout 0.1s, transform 0.4s $easeinout, background 0.4s,
        color 0.4s;
      transform: translate(0, 20px);
      @for $i from 0 to 5 {
        .nav-mobile--open &:nth-child(#{$i}) {
          transition-delay: 0.15s * $i;
        }
      }
      @extend %open-nav;
    }
    .nav-contact {
      margin-top: 45px;
      width: 158px;
      font-size: 20px;
      border: 1.5px solid #fff;
      border-radius: 32.5px;
      @include __phone {
        padding: 15px 0 17px 0;
      }
      &:hover {
        background-color: #fff;
        color: rgba(44, 59, 86, 0.95);
      }
    }
  }
}

.nav-mobile-close {
  position: absolute;
  text-indent: -9999px;
  right: 25px;
  top: 25px;
  width: 30px;
  height: 30px;
  background: url(../img/exit-btn.acdd97f8.svg) no-repeat 0 0;
  background-size: 100%;
  @extend %open-nav;
  transition: opacity 0.4s $easeinout;
  @include __phone {
    top: 32px;
    width: 22px;
    height: 22px;
  }
  .nav-mobile--open & {
    transition-delay: 0.5s;
  }
}
