.footer {
  position: relative;
  text-align: center;
  background-color: #2c3b56;
  z-index: 10;
  p {
    padding: 52px 0 50px 0;
    font-size: 16px;
    color: #fff;
    @include __phone {
      font-size: 14px;
      padding: 32px 0;
    }
  }
}
