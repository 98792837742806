/* http://meyerweb.com/eric/tools/css/reset/    v2.0 | 20110126   License: none (public domain)*/

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body,
input,
textarea,
select,
table,
button {
  font-size: 12px;
  font-family: "Open Sans", "malgun gothic", "dotum", sans-serif;
  color: #333;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 12px;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: bold;
}

em {
  font-style: normal;
}

input,
select {
  margin: 0;
  padding: 0;
}

table {
  margin: 0;
  outline: 0;
  border-collapse: collapse;
  border-spacing: 0;
}
caption,
th,
td {
  font-weight: normal;
}

a {
  text-decoration: none;
  color: #333;
}
a:hover {
  text-decoration: underline;
}
