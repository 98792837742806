@mixin __media-images($image, $ext: 'jpg') {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (max-width: 760px) {
    & {
      background-image: url(#{$image}.#{$ext});
    }
  }
  @media (min-width: 800px) {
    & {
      background-image: url(#{$image}@2x.#{$ext});
    }
  }
  @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px),
    (min-resolution: 192dpi) and (min-width: 800px) {
    & {
      background-image: url(#{$image}@3x.#{$ext});
    }
  }
}

@mixin __placeholder {
  &::placeholder {
    @content;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    @content;
  }
  ::-ms-input-placeholder {
    @content;
  }
}

@mixin __clearfix {
  *zoom: 1;
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

@mixin __word-break {
  word-break: keep-all;
  word-wrap: break-word;
}

@mixin __tablet {
  @media screen and (max-width: 1100px) and (min-width: 761px) {
    & {
      @content;
    }
  }
}

@mixin __phone {
  @media screen and (max-width: 760px) {
    & {
      @content;
    }
  }
}

@mixin __small-phone {
  @media screen and (max-width: 375px) {
    & {
      @content;
    }
  }
}
