.contactus {
  padding: 85px 0 75px;
  position: relative;
  @include __media-images('../img/contactsus-img', 'jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  .contactus_title {
    font-size: 40px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    padding-bottom: 19px;
  }
  .contactus_desc {
    font-size: 18px;
    line-height: 1.3;
    color: #fff;
    text-align: center;
    padding-bottom: 45px;
  }
  .contactus_btn {
    display: block;
    margin: 0 auto;
    width: 220px;
    height: 50px;
    box-sizing: border-box;
    line-height: 47px;
    border: solid 2px #ffffff;
    border-radius: 25px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    text-decoration: none;
    font-weight: 600;
    overflow: hidden;
    span {
      display: block;
      width: 100%;
      height: 100%;
      transition: color 0.25s, background 0.25s;
      &:hover {
        color: #3894ff;
        background-color: #fff;
      }
    }
  }
}

@media (max-width: 1100px) and (min-width: 761px) {
}
@media (max-width: 760px) {
  .section.contactus {
    background-size: 250%;
    padding-bottom: 70px;
  }
  .contactus .contactus_title {
    font-size: 28px;
    width: 330px;
    margin: 0 auto;
  }
  .contactus .contactus_desc {
    font-size: 16px;
    width: 230px;
    margin: 0 auto;
    padding-bottom: 35px;
  }
}
