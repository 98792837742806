@import 'header/hambuger';
@import 'header/navmobile';

.header {
  position: relative;
  z-index: 12;
  overflow: hidden;
  height: 100%;
  background-size: cover;
  background: url(../img/bg_adiscope_web.c468c486.jpg) 50% 50%;
}
.header__video {
  @extend %fullbox;
  z-index: 1;
  .video-poster {
    @extend %fullbox;
    z-index: 3;
    background: url(../img/bg_adiscope_web.c468c486.jpg) 50% 50% / cover no-repeat;
  }
  video {
    opacity: 0;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }
  .video-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    background-position: 0 0;
    @include __media-images('../img/pattern', 'png');
    z-index: 10;
  }
}
.video-playing video {
  opacity: 1;
  transition: opacity 1.5s $easeinout;
}
#header__logo {
  position: absolute;
  left: 30px;
  top: 28px;
  width: 143px;
  height: 39px;
  z-index: 10;
  background: url(../img/v2/logo.68fdefd4.svg) no-repeat 0 0;
  background-size: 100%;
  @include __tablet {
    left: 30px;
    top: 28px;
    width: 157px;
    height: 43px;
  }
  @include __phone {
    left: 20px;
    top: 23px;
  }
}
.nav {
  position: absolute;
  right: 30px;
  top: 25px;
  text-align: right;
  font-size: 0;
  z-index: 10;
  @include __tablet {
    display: none;
  }
  @include __phone {
    display: none;
  }
  a {
    box-sizing: border-box;
    display: inline-block;
    height: 42px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    margin-left: 34px;
    span {
      display: block;
      padding: 10px 0;
    }
  }
  .nav-btn {
    color: #fff;
    opacity: 0.7;
    transition: opacity 0.25s;
    &:hover {
      opacity: 1;
      span {
        border-bottom: 2px solid #90949b;
      }
    }
    #{&}--on span {
      border-bottom: 2px solid #90949b;
    }
  }
  .nav-contact {
    width: 122px;
    margin-left: 40px;
    border-radius: 21px;
    color: #fff;
    border: solid 2px #fff;
    transition: color 0.25s, background 0.4s;
    &:hover {
      background-color: #fff;
      color: rgba(44, 59, 86, 0.95);
    }
  }
}
.header__tbl {
  position: relative;
  z-index: 5;
  display: table;
  width: 100%;
  height: 100%;
  .header__cell {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    word-wrap: break-word;
  }
}
.header__title {
  width: 800px;
  margin: 0 auto;
  font-size: 82px;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.22;
  margin-bottom: 41px;
  @include __tablet {
    font-size: 72px;
  }
  @include __phone {
    width: 100%;
    font-size: 40px;
    line-height: 1.43;
  }
  @include __small-phone {
    font-size: 32px;;
  }
  span {
    display: inline-block;
    transform-origin: 50% 50%;
    opacity: 0;
    transform: rotateY(85deg) translate3d(30px, 0px, 50px);
    transition: transform 1s, opacity 0.5s;
  }
}
.header__explore {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 10;
  @include __phone {
    bottom: 21px;
  }
  span {
    display: block;
  }
  .header__explore-text {
    padding-bottom: 14px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    @include __phone {
      padding-bottom: 9px;
    }
  }
  .header__explore-mouse {
    width: 18px;
    height: 67px;
    margin: 0 auto;
    background: url(../img/scroll-img.3e2dd317.svg) no-repeat 50% 50%;
    @include __phone {
      width: 46px;
      height: 46px;
      background-image: url(../img/v2/img-scroll-arrow.83e55cd0.svg);
    }
  }
}
.header__tbl {
  perspective: 800px;
}
.header__nav--fixed {
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 90px;
  background-color: rgba(0, 0, 0, 0.4);
  @include __tablet {
    display: none;
  }
  @include __phone {
    display: none;
  }
  .nav-btn--on span {
    border-bottom: 2px solid rgba(255, 255, 255, 0.8);
  }
  .nav-btn:hover span {
    border-color: rgba(255, 255, 255, 0.8);
  }
}

@for $i from 0 to 45 {
  .head-visible .header__title span:nth-child(#{$i}) {
    transition-delay: 0.03s * $i;
  }
}
.head-visible .header__title span {
  opacity: 1;
  transform: rotateY(0deg) translate3d(0, 0px, 0px);
}
