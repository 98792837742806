.nav__hambtn {
  display: none;
  position: fixed;
  width: 60px;
  height: 60px;
  right: 8px;
  top: 10px;
  z-index: 80;
  @include __tablet {
    display: block;
  }
  @include __phone {
    display: block;
    right: 7px;
    top: 15px;
    transform: scale(0.7);
  }
}
.nav__hambtn:after {
  display: block;
  content: '';
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.5s $easeinout;
}
.head-visible .nav__hambtn:after {
  opacity: 0;
}
%hambtn-line {
  z-index: 1;
  display: block;
  width: 33px;
  // width: 33px;
  height: 3px;
  background-color: #fff;
  border-radius: 1.5px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -16.5px;
}
.nav__hambtn-line1 {
  @extend %hambtn-line;
  margin-top: -12px;
  transition: transform 0.5s $easeinout, opacity 0.6s $easeinout;
}
.nav__hambtn-line2 {
  @extend %hambtn-line;
  margin-top: -1.5px;
  transition: transform 0.5s $easeinout 0.2s, opacity 0.6s $easeinout;
}
.nav__hambtn-line3 {
  @extend %hambtn-line;
  margin-top: 9px;
  transition: transform 0.5s $easeinout 0.1s, opacity 0.6s $easeinout;
}
.nav-mobile--open .nav__hambtn {
  span {
    transform: translate(45px, 0);
    opacity: 0;
  }
  .nav__hambtn-line1 {
    transition-delay: 0.2s;
  }
  .nav__hambtn-line2 {
    transition-delay: 0;
  }
  .nav__hambtn-line3 {
    transition-delay: 0.3s;
  }
}
