.section.networks {
  padding-top: 140px;
  @include __tablet {
    padding-top: 80px;
  }
  @include __phone {
    padding-top: 70px;
  }
  .section__name {
    padding-bottom: 13px;
  }
}
.networks_company {
  padding-top: 57px;
  padding-bottom: 66px;
  max-width: 852px;
  margin: 0 auto;
  font-size: 0;
  @include __tablet {
    padding-top: 17px;
    padding-bottom: 60px;
  }
  @include __phone {
    padding-top: 25px;
    padding-bottom: 59px;
  }
  ul {
    @include __clearfix;
    @include __tablet {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 72px;
    }
    @include __phone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  li {
    box-sizing: border-box;
    float: left;
    margin-bottom: 14px;
    @include __tablet {
      float: none;
      margin-left: 0px !important;
      margin-right: 0px !important;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      width: 33%;
    }
    @include __phone {
      float: none;
      margin-left: 0px !important;
      margin-right: 0px !important;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      width: 33%;
    }
    &:nth-child(4n - 2) {
      margin-left: 64px;
    }
    &:nth-child(4n - 1) {
      margin-left: 70px;
    }
    &:nth-child(4n) {
      margin-left: 69px;
    }
    img {
      max-width: 100%;
      @include __tablet {
        width: 100%;
        max-width: 162px;
        max-height: auto;
      }
      @include __phone {
        width: 100%;
        max-width: 112px;
        max-height: auto;
        margin: 0 5px;
      }
      @include __small-phone {
        width: 100%;
        max-width: 100px;
        max-height: auto;
      }
    }
  }
}
.networks_header {
  margin-top: 152px;
  height: 24px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #bbc9df;
  @include __tablet {
    margin-top: 113px;
  }
  @include __phone {
    margin-top: 54px;
  }
  @at-root .official-partner + & {
    margin-top: 50px;
    margin-bottom: 3px;
    @include __tablet {
      margin-top: 40px;
    }
    @include __phone {
      margin-top: 60px;
      margin-bottom: 0;
    }
  }
}
.official-partner {
  background-color: #ffcd00;
  text-align: center;
  padding: 41px 0 41px 0;
  @include __phone {
    padding: 26px 0 24px 0;
  }
  .official-partner__title {
    position: relative;
    top: -1px;
    font-size: 18px;
    font-weight: 600;
    color: #26344a;
    vertical-align: middle;
    margin-right: 10px;
    @include __phone {
      font-size: 14px;
      margin-right: 7px;
    }
  }
  .kakao {
    margin-left: 12px;
    vertical-align: middle;
    @include __phone {
      width: 130px;
      height: 29px;
      margin-left: 7px;
    }
  }
}
