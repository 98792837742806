.section.formats {
  margin-top: 258px;
  padding-top: 140px;
  padding-bottom: 117px;
  background-color: #f8fbff;
  @include __tablet {
    padding-top: 100px;
    padding-bottom: 80px;
    .section__title {
      width: 546px;
    }
  }
  @include __phone {
    margin-top: 0;
    padding-top: 70px;
    padding-bottom: 69px;
  }
}
.format-tab__contents {
  @include __clearfix;
  width: 870px;
  margin: 68px auto 0;
  @include __tablet {
    width: 100%;
  }
  @include __phone {
    width: 100%;
  }
}
.format-tab__content {
  position: relative;
  float: left;
  width: 50%;
  height: 815px;
  @include __tablet {
    width: 100%;
    height: auto;
    float: none;
    &:last-child {
      margin-top: 75px;
    }
  }
  @include __phone {
    width: 100%;
    height: auto;
    float: none;
    &:last-child {
      margin-top: 75px;
    }
  }
  .format-tab__title {
    padding-bottom: 21px;
    text-align: center;
    font-size: 18px;
    color: #26344a;
    line-height: 1.39;
    font-weight: 600;
  }
  .format-tab__desc {
    width: 340px;
    padding-top: 11px;
    margin: 0 auto;
    text-align: center;
    font-size: 17px;
    line-height: 1.47;
    font-weight: 300;
    color: #5e6977;
  }
  .format-slider__holder,
  .format-tab__video {
    position: relative;
    width: 339px;
    height: 614px;
    margin: 0 auto;
    @include __media-images('../img/formats-phone', 'png');
    background-position: 50% 50%;
    background-size: 100%;
    .format-slider,
    video {
      border-radius: 6px;
      position: absolute;
      left: 45px;
      top: 42px;
      width: 250px;
      height: 490px;
    }
    .format-slider {
      .slides li {
        width: 250px;
        height: 490px;
        img {
          border-radius: 6px;
          max-width: 100%;
        }
      }
    }
  }
}

.flex-control-nav {
  padding-top: 61px;
  text-align: center;
}
.flex-control-nav li {
  display: inline-block;
  zoom: 1;
  *display: inline;
  position: relative;
}

.flex-control-paging li a {
  display: block;
  width: 15px;
  height: 15px;
  text-align: left;
  font-size: 0;
  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #86939e;
    transform: scale(0.7);
    transition: transform 0.25s, margin 0.25s, background-color 0.25s;
  }
}
.flex-control-paging li a:hover {
  &:after {
    background: #3894ff;
  }
}
.flex-control-paging li a.flex-active {
  &:after {
    transform: scale(1);
    background-color: #3894ff;
  }
}
