%open-mail {
  transition: opacity 0.4s $easeinout, transform 0.4s $easeinout;
  opacity: 0;
  .mail--open & {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.mail--open .mailform {
  display: block;
  z-index: 100;
}
.mailform {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: z-index 0.25s 0.25s;
  .mail--open & {
    transition-delay: 0s;
  }
  .mailform-scroll {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .mail-from__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 59, 86, 0.95);
    @extend %open-mail;
  }
  .mailform-body {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -325px 0 0 -340px;
    width: 680px;
    h2 {
      color: #fff;
      font-size: 40px;
      text-align: center;
      font-weight: normal;
      padding-bottom: 15px;
      transition: opacity 0.4s $easeinout, transform 0.4s $easeinout;
      @extend %open-mail;
    }
    .mail-field {
      position: relative;
      box-sizing: border-box;
      float: left;
      padding: 15px 10px;
      width: 50%;
      &.mail-field--large {
        width: 100%;
      }
      @extend %open-mail;
    }
  }
  select,
  input {
    height: 40px;
    font-size: 15px;
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
    border: 0 none;
    background-color: #fff;
    border-radius: 4px;
    color: #2c3b56;
    @include __placeholder {
      color: #2c3b56;
    }
  }
  select {
    top: 0;
    appearance: none;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    background: #fff url(../img/select-arw.ec2da4d3.gif) no-repeat 95% 50%;
    &::-ms-expand {
      display: none;
    }
  }
  textarea {
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border: 0 none;
    background-color: #fff;
    border-radius: 4px;
    padding: 15px;
    font-size: 15px;
    line-height: 1.5;
    height: 140px;
    overflow: auto;
    @include __placeholder {
      color: #2c3b56;
    }
  }
  label {
    display: block;
    color: #5ba7ff;
    position: absolute;
    left: 25px;
    bottom: -5px;
    font-size: 14px;
  }
}

.mail-btn {
  padding-top: 35px;
  clear: both;
  a {
    margin: 0 auto;
    text-align: center;
    display: block;
    width: 220px;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    background-image: linear-gradient(
      90deg,
      rgba(56, 148, 255, 1) 0%,
      rgba(105, 255, 227, 1) 25%,
      rgba(171, 255, 239, 1) 50%,
      rgba(105, 255, 227, 1) 75%,
      rgba(56, 148, 255, 1) 100%
    );
    background-size: 400%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    text-decoration: none;
    border-radius: 6px;
    @extend %open-mail;
  }
  a:hover,
  a:active {
    background-position: 0 0;
  }
}
.mailform-close {
  position: absolute;
  text-indent: -9999px;
  right: 60px;
  top: 60px;
  width: 36px;
  height: 36px;
  background: url(../img/exit-btn.acdd97f8.svg) no-repeat 0 0;
  background-size: 100%;
  z-index: 10;
  @extend %open-mail;
}

@media (max-width: 1100px) and (max-height: 750px) {
  .mailform .mailform-body {
    padding-top: 117px;
    position: relative;
    margin: 0 auto;
    width: 680px;
    left: auto;
    top: auto;
  }
}

@media (max-width: 1100px) and (min-width: 761px) {
}
@media (max-width: 760px) {
  .mailform-close {
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    transform: scale(0.7) !important;
  }
  .mailform .mailform-body {
    position: relative;
    margin: 0 auto;
    left: auto;
    top: auto;
    width: 90%;
    padding: 60px 0 120px 0;
  }
  .mailform .mailform-body .mail-field {
    width: 100%;
  }
}
