%clearfix {
  *zoom: 1;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}

%word-break {
  word-break: keep-all;
  word-wrap: break-word;
}

%replace-box {
  display: block;
  position: absolute;
  text-indent: -9999px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

%fullbox {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
