[data-visible] {
  opacity: 0;
  transform: translate(0px, 40px);
  transition: opacity 0.45s $easeinout, transform 0.45s $easeinout;
}
[data-visible='fromRight'] {
  transform: translate(20px, 0);
  transition: opacity 0.45s $easeinout, transform 0.45s $easeinout;
}
[data-visible='fromLeft'] {
  transform: translate(-20px, 0);
  transition: opacity 0.45s $easeinout, transform 0.45s $easeinout;
}

.head-visible .header__cell > [data-visible],
.visible > [data-visible],
.visible .format-tab > [data-visible],
.visible ul > [data-visible] {
  opacity: 1;
  transform: translate(0px, 0px);
}

.summary__second.visible .summary__group[data-visible] {
  opacity: 1;
  transform: translate(0px, 0px);
  &:nth-child(1) {
    transition-delay: 0.18s * 1.5;
  }
  &:nth-child(2) {
    transition-delay: 0.18s * 2.5;
  }
  &:nth-child(3) {
    transition-delay: 0.18s * 3.5;
  }
}

#format-tab__content-video.visible [data-visible] {
  opacity: 1;
  transform: translate(0px, 0px);
  &:nth-child(1) {
    transition-delay: 0.18s * 1.5;
  }
  &:nth-child(2) {
    transition-delay: 0.18s * 3.5;
  }
  &:nth-child(3) {
    transition-delay: 0.18s * 2.5;
  }
}

#format-tab__content-offerwall.visible [data-visible] {
  opacity: 1;
  transform: translate(0px, 0px);
  &:nth-child(1) {
    transition-delay: 0.18s * 2.5;
  }
  &:nth-child(2) {
    transition-delay: 0.18s * 4.5;
  }
  &:nth-child(3) {
    transition-delay: 0.18s * 3.5;
  }
}

@for $i from 0 to 10 {
  .visible > [data-visible]:nth-child(#{$i}) {
    transition-delay: 0.18s * $i;
  }
}

.head-visible .header__cell > [data-visible]:nth-child(1) {
  transition: opacity 1s $easeinout, transform 1s $easeinout;
}
.head-visible .header__cell > [data-visible]:nth-child(2) {
  transition: opacity 1s $easeinout 0.3s, transform 1s $easeinout 0.3s;
}
