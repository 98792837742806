path {
  fill-opacity: 0;
  transition: fill-opacity 1s;
}
.done path {
  fill-opacity: 1;
}
// #load-logo path {
//   transition: fill-opacity 0.4s;
// }

// #load-logo .side {
//   transform: translate(0px, 0px) rotate(0deg) scale(1);
//   transition: transform 0.5s cubic-bezier(1, -1.04, 0.54, 1.07);
// }

// .load-done #load-logo .side {
//   transform: translate(30px, -30px) rotate(-10deg) scale(0.4);
// }

@for $i from 1 to 40 {
  path:nth-child(#{$i}) {
    transition-delay: 0.05s * $i;
  }
}

.feature-img-4 [fill-opacity="0"] {
  fill-opacity: 0;
}

.feature-img-4 #Stroke-3 {
  fill-opacity: 0.557687953;
}

@keyframes go-fly {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(-10px, 10px) rotate(0deg);
  }
  50% {
    transform: translate(20px, -20px) rotate(0deg);
  }
  100% {
    transform: translate(40px, 40px) rotate(0deg);
  }
}
